import React, { useState } from 'react';
import { Table, Button, Divider, Input, Icon, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { ICandidate } from '../../../constants/models';
import { AdminRoutes } from '../../../constants/routes';
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton';

type Props = {
  candidates: ICandidate[];
  confirmDelete: (type: string, id: string) => void;
};

const CandidateList: React.FC<Props> = (props: Props) => {
  const { candidates, confirmDelete } = props;

  const [searchText, setSearchText] = useState('');
  const [activeFilter, setActiveFilter] = useState('both');
  let searchInput: any;

  const getColumnSearchProps = (dataIndex: number | string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: { setSelectedKeys: any; selectedKeys: any; confirm: any; clearFilters: any }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: any) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const handleSearch = (selectedKeys: any, confirm: () => void) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (text: string) => <span>{text}</span>,
      sorter: (a: any, b: any) => a.jobTitle.length - b.jobTitle.length,
      ...getColumnSearchProps('jobTitle'),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: string) => <span>{text}</span>,
      sorter: (a: any, b: any) => a.firstName.length - b.firstName.length,
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text: string) => <span>{text}</span>,
      sorter: (a: any, b: any) => a.lastName.length - b.lastName.length,
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Skills',
      dataIndex: 'skills',
      key: 'skills',
      ellipsis: true,
      render: (texts: string[]) => <span>{texts.join(', ')}</span>,
      ...getColumnSearchProps('skills'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (candidate: ICandidate) => {
        return (
          <span>
            <Link to={`${AdminRoutes.EDIT_CANDIDATE}/${candidate.id}`}>
              <Button
                type="default"
                style={{
                  color: '#faad14'
                }}
              >Edit
              </Button>
            </Link>
            <Divider type="vertical" />
            <Button type="danger" onClick={() => confirmDelete('candidate', candidate.id || '')}>Delete</Button>
          </span>
        )
      },
    },
  ]

  return (
    <div>
      <FilterArea>
        Filter:
        <Select style={{ width: '200px', marginLeft: '10px' }} value={activeFilter} onChange={(value: string) => setActiveFilter(value)}>
          <Select.Option value="both">All</Select.Option>
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="inactive">Inactive</Select.Option>
        </Select>
      </FilterArea>
      <Table
        bordered
        columns={columns}
        dataSource={candidates.filter((candidate: ICandidate) => {
          switch (activeFilter) {
            case 'both':
              return true;
            case 'active':
              return candidate.status === 'AVAILABLE';
            case 'inactive':
              return candidate.status === 'INACTIVE';
            default:
              return true;
          }
        })}
        pagination={false}
        rowKey="id"
      />
      <div style={{ marginTop: '20px' }}>
        <Link to={AdminRoutes.CREATE_CANDIDATE}>
          <PrimaryButton type="primary">Create Candidate</PrimaryButton>
        </Link>
      </div>
    </div>
  );
}

const FilterArea = styled.div`
  margin-bottom: 10px;
`

export default CandidateList;