import axios from 'axios';
import Qs from 'qs';
import { storage } from './utils/storage';
import { msalInstance, loginRequest } from './utils/msalInstance';

export class NeedsAuthError extends Error { }

async function getToken(account: any) {
  const { accessToken } = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: loginRequest.scopes
  });

  return accessToken;
}

export async function getAppToken() {
  try {
    const accounts = msalInstance.getAllAccounts();
    console.log(accounts)
    if (accounts.length > 0) {

      let account = msalInstance.getActiveAccount();
      if (!account) {
        msalInstance.setActiveAccount(accounts[0])
        account = accounts[0]
      }
      const roles = account.idTokenClaims?.roles
      console.log("roles", roles)
      if (!roles || !roles.some((item: string) => item === 'login.select')) {
        msalInstance.setActiveAccount(null)
        // localStorage.clear();
        window.location.assign(process.env.REACT_APP_REDIRECT_URL || '');
      }

      console.log(account);
      const accessToken = await getToken(account);
      const config = {
        "Content-Type": "application/json",
        headers: { Authorization: `Bearer ${accessToken}` }
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth`,
        {},
        config
      );
      const { data } = response;
      const email = account?.idTokenClaims?.email as string;
      return { email, ...data }
    } else {
      throw new NeedsAuthError("No accounts");
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.name === "InteractionRequiredAuthError") {
        console.log("interaction required");
        throw new NeedsAuthError("Interaction required")
      }
    }
    throw error;
  }
}


const headers = {
  // Authorization: storage.get('token'),
  'Content-Type': 'application/json'
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers,
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' });
  }
});


api.interceptors.request.use(config => {
  config.headers['Authorization'] = storage.get('token')
  return config
}, error => {
  return Promise.reject(error);
})

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    // Do something with response error
    if (error.response.status === 401) {
      console.error(error.config)
      if (!error.config['retry']) {
        error.config['retry'] = true
        try {
          const { token } = await getAppToken()
          console.log(token)
          storage.set('token', token)
          error.config.headers['Authorization'] = token
          return axios.request(error.config)

        } catch (tokenError) {
          return Promise.reject(tokenError)
        }


      } else {
        // storage.clear();
      }
    }
    return Promise.reject(error.response);
  }
);

export default api