import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import { Provider } from "react-redux";
import configureStore from "./store/index";
import App from "./containers/App/App";
import "antd/dist/antd.less";
import { storage } from "./utils/storage";

// initialize store
const now = new Date();
now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000);
let auth = {};

const tokenExpires = new Date(storage.get("tokenExpiration") || 0);
console.log("expire", tokenExpires);
console.log("now", now);

if (now < tokenExpires) {
  auth = {
    token: storage.get("token"),
    email: storage.get("email"),
    displayName: storage.get("displayName"),
    permissions: JSON.parse(storage.get("permissions") || "[]"),
    tokenExpiration: storage.get("tokenExpiration") || 0
  };
}

const store = configureStore({
  auth
});

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
