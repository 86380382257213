import React, { FC } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./App.scss";
import Candidates from "../Candidates/Candidates";
import Admin from "../Admin/Admin";
import { Routes, AdminRoutes } from "../../constants/routes";
import Home from "../../Static/Home/Home";
import CreateCandidate from "../CreateCandidate/CreateCandidate";
import CreateCustomer from "../CreateCustomer/CreateCustomer";
import CandidateDetails from "../CandidateDetails/CandidateDetails";
import { usePageViews } from "../../services/usePageViews";
import { PrivateRoute } from "../../utils/private-route";

type Props = {
  token: string;
  permissions: string[];
};

const PageTracker: FC = () => {
  usePageViews();
  return null;
};

const App: React.FC<Props> = ({ token, permissions }: Props) => {
  return (
    <BrowserRouter>
      <PageTracker />
      <Switch>
        <PrivateRoute
          token={token}
          permissions={permissions}
          path={AdminRoutes.CREATE_CANDIDATE}
          component={CreateCandidate}
        />
        <PrivateRoute
          token={token}
          permissions={permissions}
          path={AdminRoutes.EDIT_CANDIDATE_PATH}
          component={CreateCandidate}
        />
        <PrivateRoute
          token={token}
          permissions={permissions}
          path={AdminRoutes.CREATE_CUSTOMER}
          component={CreateCustomer}
        />
        <PrivateRoute
          token={token}
          permissions={permissions}
          path={AdminRoutes.EDIT_CUSTOMER_PATH}
          component={CreateCustomer}
        />
        <PrivateRoute
          token={token}
          permissions={permissions}
          exact
          path={Routes.ADMIN}
          component={Admin}
        />
        <PrivateRoute
          token={token}
          permissions={permissions}
          exact
          path={Routes.APP}
          component={Candidates}
        />
        <PrivateRoute
          token={token}
          permissions={permissions}
          exact
          path={Routes.RESUME_PATH}
          component={CandidateDetails}
        />
        <Route exact path={Routes.HOME} component={Home} />
        <Route component={Home} />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: any) => ({
  token: state.auth.token,
  permissions: state.auth.permissions
});

export default connect(mapStateToProps)(App);
