import React, { useEffect, useState } from "react";
import { msalInstance, loginRequest } from "../../utils/msalInstance";
import { login, IAuth } from "../../store/actions/authActions";
import { connect } from "react-redux";
import { getAppToken } from "../../axios";
import { NeedsAuthError } from "../../axios";
import "./Home.css";

type language = "en" | "nl";

type Props = {
  login: (auth: IAuth, callback?: () => void) => void;
};

const Home: React.FC<Props> = ({ login }: Props) => {
  const [language, setLanguage] = useState<language>("en");

  useEffect(() => {
    async function handleLogin() {
      try {
        await msalInstance.handleRedirectPromise();
        const data = await getAppToken();
        console.log(data);
        login(data);
      } catch (error) {
        console.error(error);
        if (error instanceof NeedsAuthError) {
          msalInstance.loginRedirect(loginRequest);
        }
      }
    }

    handleLogin();
  }, []);

  return <div />;
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, { login })(Home);
