import { Action, Reducer } from 'redux';
import * as actionTypes from '../actions/actionTypes';
import { IFilter } from '../../constants/models';

const initialFilters: IFilter = {
  nationality: [],
  experience: [],
  residence: [],
  jobTitle: [],
  skill: [],
  salaryRange: [],
  contractType: [],
  visaRequired: ''
};

interface UpdateAction extends Action<typeof actionTypes.UPDATE_FILTERS> {
  payload: IFilter;
}

type FiltersActionTypes = UpdateAction;

const filtersReducer: Reducer<IFilter, FiltersActionTypes> = (state = initialFilters, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERS:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default filtersReducer;
