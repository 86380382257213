import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const DefaultButton = (props: any) => {
  return (
    <StyledButton
      type="default"
      {...props}
    />
  );
};

const StyledButton = styled(Button)`
  &:hover, &:active {
    border-color: #fa7452;
    color:  #fa7452;
  }
`

export default DefaultButton;
