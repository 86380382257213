import * as actionTypes from '../actions/actionTypes';

export interface IState {
  email: string;
  token: string;
  displayName: string;
  loading: boolean;
  permissions: string[];
}

const initialState: IState = {
  email: '',
  token: '',
  displayName: '',
  loading: false,
  permissions: []
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        permissions: []
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        permissions: []
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        email: action.auth.email,
        // token: action.auth.token,
        displayName: action.auth.displayName,
        loading: false,
        permissions: action.auth.permissions
      };
    case actionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        email: null,
        token: null,
        displayName: null,
        permissions: []
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        email: null,
        token: null,
        displayName: null,
        loading: false,
        permissions: []
      };
    default:
      return { ...state };
  }
};

export default authReducer;
