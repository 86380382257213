import { message } from 'antd';

type NotificationType = 'success' | 'error' | 'warning';

const notificationCreator = (type: NotificationType, msg: string): void => {
  switch (type) {
    case 'success':
      message.success(msg);
      break;
    case 'error':
      message.error(msg);
      break;
    case 'warning':
      message.warning(msg);
      break;
    default:
      console.log('undefined notification type');
  }
};

export default notificationCreator;
