import * as actionTypes from '../actions/actionTypes';
import { ICandidate } from '../../constants/models';

type CandidateState = {
  candidates: ICandidate[];
  candidate: ICandidate | null;
  isLoading: boolean;
  error: any;
};

const initialState: CandidateState = {
  candidates: [],
  candidate: null,
  isLoading: false,
  error: null
};

const candidateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_CANDIDATES_REQUEST:
      return { ...state, candidates: [], isLoading: true, error: null };
    case actionTypes.FETCH_CANDIDATES_SUCCESS:
      return { ...state, candidates: action.candidates, isLoading: false, error: null };
    case actionTypes.FETCH_CANDIDATES_FAILURE:
      return { ...state, candidates: [], isLoading: false, error: action.error }
    case actionTypes.FETCH_CANDIDATE_REQUEST:
      return { ...state, isLoading: true, error: null, candidate: null };
    case actionTypes.FETCH_CANDIDATE_SUCCESS:
      return { ...state, isLoading: false, error: null, candidate: action.candidate };
    case actionTypes.FETCH_CANDIDATE_FAILURE:
      return { ...state, isLoading: false, error: action.error, candidate: null }
    case actionTypes.CREATE_CANDIDATE_REQUEST:
      return { ...state, isLoading: true, error: null, candidate: null };
    case actionTypes.CREATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        candidate: action.candidate,
        candidates: [...state.candidates, action.candidate]
      };
    case actionTypes.CREATE_CANDIDATE_FAILURE:
      return { ...state, isLoading: false, error: action.error, candidate: null }
    case actionTypes.UPDATE_CANDIDATE_REQUEST:
      return { ...state, isLoading: true, error: null, candidate: null };
    case actionTypes.UPDATE_CANDIDATE_SUCCESS:
      return { ...state, isLoading: false, error: null, candidate: action.candidate };
    case actionTypes.UPDATE_CANDIDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        candidate: null,
        candidates: state.candidates.map(c => (c.id === action.candidate.id) ? action.candidate : c)
      }
    case actionTypes.DELETE_CANDIDATE_REQUEST:
      return { ...state, isLoading: true, error: null, candidate: null };
    case actionTypes.DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        candidate: null,
        candidates: state.candidates.filter(c => c.id !== action.id)
      };
    case actionTypes.DELETE_CANDIDATE_FAILURE:
      return { ...state, isLoading: false, error: action.error, candidate: null }
    default:
      return { ...state };
  }
}

export default candidateReducer;