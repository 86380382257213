import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Card,
  Typography,
  Divider,
  Tag,
  Button,
  Col,
  Row,
  Icon,
  Popover,
  Checkbox
} from "antd";
import { Link } from "react-router-dom";
import { ICandidate } from "../../../constants/models";
import { PRIMARY, GRAY_2 } from "../../../constants/colors";
import notify from "../../../services/notificationCreator";
import { Routes } from "../../../constants/routes";
import { downloadCV } from "../../../utils/download-cv";

type Props = {
  candidate: ICandidate;
  displayName: string;
  isAmbassador: boolean;
};

const services = [
  {
    title: "Relocation",
    shortDescription:
      "Trip to the Netherlands + Housing + BSN appointment + Dutch bank account + TBC appointment (= dependent on nationality) + Health insurance",
    longDescription:
      "We will help with the moving from the country of residence to the Netherlands. This involves aligning the travel date, arrange a short or long stay apartment, scheduling an appointment for the first registration and provide assistance with the required documents, give information regarding the opening of a Dutch bank account or arrange an appointment, will prepare and arrange the TBC appointment (On the IND website you can find a list with countries), and we give information about the health insurance. Also, we make sure all is arranged, in time."
  },
  {
    title: "Onboarding",
    shortDescription:
      "Visa application/Residence permit + Payrolling + 30% ruling",
    longDescription:
      "We’ll basically arrange everything! Firstly, we will gather documents and apply for the visa at the IND after which we’ll schedule an appointment at the embassy as soon as we have a positive decision. Once your soon-to-be colleague has collected his/her visa at the embassy we will inform you about it. Then you – or we in case you prefer assistance with the relocation, as well – should arrange the relocation (trip/housing/etc). As soon as your colleague has arrived, started working, and has a BSN number + Dutch bank account, we’ll payroll your candidate and apply for the 30% ruling."
  }
];

const Resume: React.FC<Props> = ({
  candidate,
  displayName,
  isAmbassador
}: Props) => {
  console.log("resume details", candidate);
  const { Title, Paragraph, Text } = Typography;
  const [selectedServices, setSelectedServices] = useState([true, true]);

  useEffect(() => {
    if (!candidate.visaRequired) {
      selectService(1, false);
    }
  }, []);

  const selectService = (index: number, checked: boolean) => {
    const tempServices = [...selectedServices];
    tempServices[index] = checked;
    setSelectedServices(tempServices);
  };

  const sendMail = (isAmbassador: boolean) => {
    if (isAmbassador) {
      notify("error", "You don't have permission to quotation.");
      return;
    }

    const subject = `Quotation request ${candidate.firstName} ${candidate.lastName}`;
    console.log(
      "sending",
      services
        .filter((service, index) => selectedServices[index] === true)
        .map(service => `${service.title}%0D%0A`)
    );

    let message = `Dear Remco,%0D%0A %0D%0AI am interested in ${
      candidate.firstName
    } ${
      candidate.lastName
    } who requires a visa.%0D%0ACould you please provide me with a quotation for: %0D%0A %0D%0A${services
      .filter((service, index) => selectedServices[index] === true)
      .map(service => `*${service.title.toString()} %0D%0A`)
      .join("")}%0D%0A${displayName}`;

    message = message.replace(/(?:\r\n|\r|\n)/g, "%0D%0A ");

    window.open(`mailto:remco@thefly.io?subject=${subject}&body=${message}`);
  };

  function getContactData(type: string) {
    if (type === "name") {
      const { firstName, lastName } = candidate;

      if (isAmbassador) {
        return (
          <>{`Johnathan Doe ${!!(Math.random() > 0.5) ? "Fakename" : ""}`}</>
        );
      }

      return (
        <>
          {firstName} {lastName}
        </>
      );
    }

    if (type === "phone") {
      const { phoneNumber } = candidate.contactInfo;
      if (!phoneNumber) return null;

      if (isAmbassador) {
        const fakenumber = "(31) 6 5555555";
        return <a href={`skype:${fakenumber}?chat`}>{fakenumber}</a>;
      }
      return <a href={`skype:${phoneNumber}?chat`}>{phoneNumber}</a>;
    }

    if (type === "email") {
      const { email } = candidate.contactInfo;
      if (!email) return null;

      if (isAmbassador) {
        const fakemail = "fakemail.candidate@fakemail.mail";
        return <a href={`mailto:${fakemail}`}>{fakemail}</a>;
      }
      return <a href={`mailto:${email}`}>{email}</a>;
    }

    if (type === "residence") {
      const { residence } = candidate;
      if (!residence) return null;

      if (isAmbassador) {
        return <>Residence</>;
      }
      return <>{residence}</>;
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem"
        }}
      >
        <Link to={Routes.APP}>
          <Button>Return to overview</Button>
        </Link>
        <Button
          type="primary"
          style={{
            backgroundColor: PRIMARY,
            borderColor: PRIMARY
          }}
          onClick={() => {
            if (isAmbassador) {
              notify("error", "You don't have permission to download cv.");
              return;
            }
            downloadCV(
              candidate.cvFileName,
              `${candidate.firstName} ${candidate.lastName}`
            );
          }}
        >
          Download CV
        </Button>
      </div>
      <Card>
        <Typography>
          <Title style={{ textTransform: "capitalize" }}>
            {candidate.jobTitle}
          </Title>
          <Paragraph>
            <Row>
              <Col span={12}>
                <Title level={3}>Skills</Title>
                <Text>
                  {candidate.skills.map(skill => (
                    <Tag key={skill} color="#fa7452">
                      {skill}
                    </Tag>
                  ))}
                </Text>
              </Col>
              <Col span={12}>
                <Line>
                  <Icon
                    type="check-circle"
                    theme="filled"
                    style={{
                      color: PRIMARY,
                      fontSize: "1.5rem",
                      marginRight: "10px",
                      marginLeft: "auto"
                    }}
                  />
                  <Text style={{ fontSize: "1.5rem" }}>
                    {candidate.visaRequired
                      ? "Visa Required"
                      : "Visa is not Required"}
                  </Text>
                </Line>
                <Line>
                  <Text
                    strong
                    style={{ marginLeft: "auto", marginRight: "10px" }}
                  >
                    Nationality:{" "}
                  </Text>
                  <Text>{candidate.nationality}</Text>
                </Line>
              </Col>
            </Row>
          </Paragraph>

          <Paragraph>
            <Title level={3}>Salary Indication</Title>
            <Text>{candidate.salary}</Text>
          </Paragraph>

          {candidate.experience && (
            <Paragraph>
              <Title level={3}>Experience</Title>
              <Text>{candidate.experience} years</Text>
            </Paragraph>
          )}

          <Paragraph>
            {candidate.contractType.length > 1 && (
              <Title level={3}>
                Looking for an opportunity as an{" "}
                <Text style={{ textTransform: "lowercase", color: PRIMARY }}>
                  employee
                </Text>{" "}
                or{" "}
                <Text style={{ textTransform: "lowercase", color: PRIMARY }}>
                  freelance
                </Text>
              </Title>
            )}
            {candidate.contractType.length === 1 &&
              candidate.contractType.includes("PERMANENT") && (
                <Title level={3}>
                  Looking for an opportunity as an{" "}
                  <Text style={{ textTransform: "lowercase", color: PRIMARY }}>
                    employee
                  </Text>
                </Title>
              )}
            {candidate.contractType.length === 1 &&
              candidate.contractType.includes("FREELANCE") && (
                <Title level={3}>
                  Looking for a{" "}
                  <Text style={{ textTransform: "lowercase", color: PRIMARY }}>
                    freelance
                  </Text>{" "}
                  opportunity
                </Title>
              )}
          </Paragraph>

          <Paragraph>
            <Title level={3}>Contact</Title>
            <div>
              <FilterableText isAmbassador={isAmbassador} strong>
                {getContactData("name")}
              </FilterableText>
            </div>
            <div>
              <FilterableText isAmbassador={isAmbassador}>
                {getContactData("phone")}
              </FilterableText>
            </div>
            <div>
              <FilterableText isAmbassador={isAmbassador}>
                {getContactData("email")}
              </FilterableText>
            </div>
            <Line>
              <Icon
                type="environment"
                style={{ color: PRIMARY, marginRight: "10px" }}
              />
              <FilterableText isAmbassador={isAmbassador}>
                {getContactData("residence")}
              </FilterableText>
            </Line>
          </Paragraph>
        </Typography>
        <Divider />
        <div>
          <ServiceTitle>
            {candidate.visaRequired ? (
              <div>
                Visa Required? <br />
                Don’t worry, we can help you!
              </div>
            ) : (
              <div>
                Need some help with the relocation of your soon-to-be-colleague?
              </div>
            )}
          </ServiceTitle>
          <ServiceDescription>
            {candidate.visaRequired ? (
              <div>
                We can arrange the entire onboarding and/or arrange the
                relocation. <br />
                Choose one or both! We’ll send you a free quotation in 1-2
                working days.
              </div>
            ) : (
              <div>
                We can arrange the entire relocation for you and will send you a
                free quotation in 1-2 working days.
              </div>
            )}
            {/* We can arrange the entire onboarding and/or arrange the relocation. <br />
            Choose one or both! We’ll send you a free quotation in 1-2 working days. */}
          </ServiceDescription>
          <ServiceOptions>
            {services
              .filter(
                (service, index) => !(index === 1 && !candidate.visaRequired)
              )
              .map((service, index) => (
                <ServiceOption key={service.title}>
                  <div>
                    <Checkbox
                      style={{
                        position: "absolute",
                        marginLeft: "-30px"
                      }}
                      checked={selectedServices[index]}
                      onChange={e => selectService(index, e.target.checked)}
                    />
                    <h5>
                      {service.title}
                      <Popover
                        content={
                          <div style={{ maxWidth: "500px" }}>
                            {service.longDescription}
                          </div>
                        }
                      >
                        <Icon
                          type="info-circle"
                          theme="filled"
                          style={{
                            color: PRIMARY,
                            position: "absolute",
                            marginLeft: "10px",
                            marginTop: "-4px",
                            fontSize: "1.1  rem"
                          }}
                        />
                      </Popover>
                    </h5>
                  </div>
                  <em>{service.shortDescription}</em>
                </ServiceOption>
              ))}
          </ServiceOptions>
          <Button
            onClick={() => sendMail(isAmbassador)}
            disabled={!selectedServices.some(_service => _service === true)}
            style={{
              display: "block",
              margin: "35px auto",
              height: "50px",
              width: "250px",
              fontSize: "1.1rem"
            }}
            type="primary"
          >
            Send me a free quotation!
          </Button>
          <ServiceNote>
            <em>
              P.s. we only offer these services for candidates who are looking
              for an opportunity as an employee
            </em>
          </ServiceNote>
        </div>
      </Card>
    </div>
  );
};

const ServiceTitle = styled.h3`
  text-align: center;
`;

const ServiceDescription = styled.div`
  text-align: center;
  margin-top: 35px;
  font-size: 1.1rem;
`;

const ServiceOptions = styled.ul`
  margin: 30px auto;
  max-width: 600px;
`;

const ServiceOption = styled.li`
  display: block;
  margin-bottom: 30px;
`;

const ServiceNote = styled.p`
  text-align: center;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
`;

// @ts-ignore
const FilterableText = styled(({ isAmbassador, children, ...rest }) => (
  <Typography.Text {...rest}>{children}</Typography.Text>
))`
  filter: ${({ isAmbassador }: { isAmbassador?: boolean }) =>
    isAmbassador ? "blur(3px)" : "blur(0)"};
  pointer-events: ${({ isAmbassador }: { isAmbassador?: boolean }) =>
    isAmbassador ? "none" : "auto"};
`;

export default Resume;
