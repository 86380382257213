import { combineReducers } from 'redux';
import authReducer from './auth';
import candidateReducer from './candidate';
import customerReducer from './customer';
import filtersReducer from './filters';

export default combineReducers({
  candidate: candidateReducer,
  auth: authReducer,
  customer: customerReducer,
  filters: filtersReducer
});
