import React from 'react';
import styled from 'styled-components';
import { Card, Tag } from 'antd';
import { Link } from 'react-router-dom';

import { ICandidate } from '../../../constants/models';
import { Routes } from '../../../constants/routes';

type Props = {
  candidate: ICandidate;
  isAmbassador: boolean;
};

const SKILL_LIMIT = 5;

const getStatus: React.FC<any> = (status: string) => {
  switch (status) {
    case 'AVAILABLE':
      return (
        <span>
          <span className="status-icon green" />
          <StatusIcon type={status} />
          Available
        </span>
      );
    default:
      return (
        <span>
          <span className="status-icon red" />
          <StatusIcon type={status} />
          Inactive
        </span>
      );
  }
};

const CandidateListItem: React.FC<Props> = (props: Props) => {
  const dev = props.candidate;
  const card = (
    <DeveloperCard key={dev.id} size="small">
      <Line>
        <DevStatus>{getStatus(dev.status)}</DevStatus>
      </Line>
      <Line>
        <DevTitle>{dev.jobTitle}</DevTitle>
      </Line>
      <Line>
        <Text isAmbassador={props.isAmbassador}>
          {dev.firstName} {dev.lastName} ({dev.dateOfBirth})
        </Text>
      </Line>
      <SkillLine>
        {dev.skills.slice(0, SKILL_LIMIT).map((skill: string) => (
          <Tag key={skill} color="#fa7452">
            {skill}
          </Tag>
        ))}
      </SkillLine>
      <Line>
        <DevLocation>
          <Text isAmbassador={props.isAmbassador}>{dev.residence}</Text>
        </DevLocation>
      </Line>
      <Line>
        <DevExperience>
          <Text isAmbassador={props.isAmbassador}>{dev.experience} years of experience</Text>
        </DevExperience>
      </Line>
    </DeveloperCard>
  );

  return (
    <div>
      {/* {props.isAmbassador ? card : ( */}
      <Link key={dev.id} to={`${Routes.RESUME}/${dev.id}`}>
        {card}
      </Link>
      {/* )} */}
    </div>
  );
};

const DeveloperCard = styled(Card)`
  margin-bottom: 25px !important;
`;

const Line = styled.div`
  margin: 1px 5px;
`;

const SkillLine = styled.div`
  margin: 7px 5px;
`;

const StatusIcon = styled.span`
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  border-radius: 50%;
  transform: translateY(-1px);
  margin-right: 0.375rem;
  vertical-align: middle;
  background-color: ${(props: { type: string }) => {
    switch (props.type) {
      case 'AVAILABLE':
        return '#28a745';
      default:
        return '#dc3545';
    }
  }};
`;

const DevStatus = styled.span`
  margin-right: 30px;
`;

const DevTitle = styled.span`
  font-weight: bold;
  margin-right: 30px;
`;

const DevLocation = styled.span`
  margin-right: 30px;
`;

const DevExperience = styled.span`
  margin-right: 30px;
`;

const Text = styled.span`
  filter: ${(props: { isAmbassador: boolean }) => (props.isAmbassador ? 'blur(3px)' : 'blur(0)')};
`;

export default CandidateListItem;
