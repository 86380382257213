import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function usePageViews(): void {
  const { pathname } = useLocation();
  useEffect(() => {
    // Google Analytics could be potentially blocked by AdBlocker or similar tool
    if ('ga' in global) {
      ga('send', 'pageview', pathname);
    }
  }, [pathname]);
}
