import React from 'react';
import { Button } from 'antd';

const PrimaryButton = (props: any) => {
  return (
    <Button
      style={{ backgroundColor: '#fa7452', borderColor: '#fa7452' }}
      type="primary"
      {...props}
    />
  );
};

export default PrimaryButton;
