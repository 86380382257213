import { IBusinessUserDetails } from '../../constants/models';
import Plans from '../../constants/plans';
import PaymentFrequency from '../../constants/payment-frequency';

type INormalizedBusinessUserDetails = Omit<IBusinessUserDetails, 'subscription'> & {
  subscription: {
    pricingPlan: Plans | 'Unknown';
    paymentFrequency: PaymentFrequency | 'Unknown';
  };
};

export const normalizeCustomer = (
  customer: IBusinessUserDetails
): INormalizedBusinessUserDetails => ({
  ...customer,
  subscription: {
    pricingPlan:
      (customer.subscription &&
        customer.subscription.pricingPlan &&
        Plans[customer.subscription.pricingPlan]) ||
      'Unknown',
    paymentFrequency:
      (customer.subscription &&
        customer.subscription.paymentFrequency &&
        PaymentFrequency[customer.subscription.paymentFrequency]) ||
      'Unknown'
  }
});
