import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { msalInstance } from "../../utils/msalInstance";

import { logout } from "../../store/actions/authActions";
import Logo from "../../assets/images/logo.png";
import { GRAY_1, PINK_4 } from "../../constants/colors";
import { Routes } from "../../constants/routes";
import PrimaryButton from "../../components/UI/PrimaryButton/PrimaryButton";
import DefaultButton from "../../components/UI/DefaultButton/DefaultButton";
import { isAmbassadorSelector } from "../../store/selectors/auth";

type Props = {
  logout: (token: string) => void;
  token: string;
  permissions: string[];
  displayName: string;
  isAmbassador: boolean;
};

class Header extends Component<Props> {
  handleLogout = () => {
    this.props.logout(this.props.token);
    // localStorage.clear();
    msalInstance.logoutRedirect();
  };

  render() {
    const { token, permissions, displayName, isAmbassador } = this.props;

    const authMenu = (
      <React.Fragment>
        <Greeting>Hi, {displayName}</Greeting>
        {permissions &&
          permissions.includes("MANAGE_CANDIDATES") &&
          permissions.includes("MANAGE_CUSTOMERS") && (
            <NavLink>
              <DefaultButton>
                <Link to={Routes.ADMIN}>Admin</Link>
              </DefaultButton>
            </NavLink>
          )}
        <NavLink>
          <PrimaryButton>
            <Link to={Routes.HOME} onClick={this.handleLogout}>
              {isAmbassador ? "Back to homepage" : "Logout"}
            </Link>
          </PrimaryButton>
        </NavLink>
      </React.Fragment>
    );

    return token ? (
      <HeaderWrapper>
        <div className="container">
          <HeaderDiv>
            <LogoWrapper>
              <Link to={Routes.APP}>
                <LogoImage src={Logo} alt="YourExpats" />
              </Link>
            </LogoWrapper>
            <Menu>{authMenu}</Menu>
          </HeaderDiv>
        </div>
      </HeaderWrapper>
    ) : null;
  }
}
const Greeting = styled.span`
  margin-right: 2vw;
  @media only screen and (max-width: 490px) {
    display: none;
  }
`;

const HeaderWrapper = styled.header`
  height: 60px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  background-color: #ffffff;
  display: flex;
  align-items: center;
`;

const HeaderDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 25px;
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;
  color: ${GRAY_1};
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavLink = styled.li`
  margin-left: 5px;
  margin-right: 5px;
`;

const StyledLink = styled(Link)`
  color: ${GRAY_1};

  &:hover {
    color: ${PINK_4};
  }
`;

const mapStateToProps = (state: any) => ({
  token: state.auth.token,
  permissions: state.auth.permissions,
  displayName: state.auth.displayName,
  isAmbassador: isAmbassadorSelector(state)
});

export default connect(mapStateToProps, { logout })(Header);
