import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  AutoComplete,
  Checkbox,
} from 'antd';
import { Redirect } from 'react-router';
import axios from '../../axios';

import { createCandidate, updateCandidate } from '../../store/actions/candidateActions';
import {
  ICountry,
  ISkill,
  ISalaryRange,
  IJobTitle,
  ICandidate,
  IExperience
} from '../../constants/models';
import { AdminRoutes, Routes } from '../../constants/routes';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import { downloadCV } from '../../utils/download-cv';

type Props = {
  match: any;
  history: any;
  createCandidate: (candidate: ICandidate) => void;
  updateCandidate: (candidate: ICandidate) => void;
};

type State = {
  editMode: boolean;
  countryList: ICountry[];
  skillList: any[];
  salaryRangeList: ISalaryRange[];
  jobTitleList: any[];
  experienceList: IExperience[];
  id: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  nationality: string;
  residence: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
  skills: string[];
  salary: string;
  contractType: string[];
  cvFileName: string;
  visaRequired: boolean;
  status: string;
  experience: string;
};

class CreateCandidate extends Component<Props, State> {
  state = {
    countryList: [],
    skillList: [],
    salaryRangeList: [],
    jobTitleList: [],
    experienceList: [],
    editMode: false,
    id: '',
    firstName: '',
    lastName: '',
    gender: 'Male',
    dateOfBirth: '1990-01-01',
    nationality: '',
    residence: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
    skills: [],
    salary: '€30000 - €40000',
    contractType: [],
    cvFileName: '',
    visaRequired: false,
    status: 'AVAILABLE',
    experience: '4 - 6'
  };

  componentDidMount() {
    this.getFormData();
    console.log('initializing', this.props);
    if (this.props.match.path === AdminRoutes.EDIT_CANDIDATE_PATH) {
      if (this.props.match.params.id) {
        this.initializeEditForm(this.props.match.params.id);
      } else {
        console.log('invalid user id');
        <Redirect to={Routes.ADMIN} />;
      }
    }
  }

  initializeEditForm = (id: string) => {
    axios
      .get(`candidate/${id}`)
      .then(res => res.data)
      .then((candidate: ICandidate) => {
        this.setState({
          editMode: true,
          id: candidate.id || '',
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          gender: candidate.gender,
          dateOfBirth: candidate.dateOfBirth,
          nationality: candidate.nationality,
          residence: candidate.residence,
          email: candidate.contactInfo.email,
          phoneNumber: candidate.contactInfo.phoneNumber,
          jobTitle: candidate.jobTitle,
          skills: candidate.skills,
          salary: candidate.salary,
          contractType: candidate.contractType,
          cvFileName: candidate.cvFileName,
          visaRequired: candidate.visaRequired,
          status: candidate.status,
          experience: candidate.experience
        });
      })
      .catch(err => console.log(err));
  };

  getFormData = () => {
    axios
      .get('form/country')
      .then(res => res.data)
      .then((countryList: ICountry[]) => {
        this.setState({ countryList });
      })
      .catch(err => console.log(err));

    axios
      .get('form/skill')
      .then(res => res.data)
      .then((skillList: ISkill[]) => {
        const titles = skillList.map((title: ISkill) => (
          <Select.Option key={title.name} value={title.name}>
            {title.name}
          </Select.Option>
        ));
        this.setState({ skillList: titles });
      })
      .catch(err => console.log(err));

    axios
      .get('form/salary-range')
      .then(res => res.data)
      .then((salaryRangeList: ISalaryRange[]) => {
        this.setState({ salaryRangeList });
      })
      .catch(err => console.log(err));

    axios
      .get('form/job-title')
      .then(res => res.data)
      .then((jobTitleList: IJobTitle[]) => {
        const titles = jobTitleList.map((title: IJobTitle) => title.name);
        this.setState({ jobTitleList: titles });
      })
      .catch(err => console.log(err));

    axios
      .get('form/experience')
      .then(res => res.data)
      .then((experienceList: IExperience[]) => {
        this.setState({ experienceList });
      })
      .catch(err => console.log(err));
  };

  handleUpload = (event: any) => {
    console.log('uploadevent', event);
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    axios
      .post('cv-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => res.data)
      .then(file => {
        console.log('upload is fine', file);
        // @ts-ignore
        this.setState({ cvFileName: file.fileName });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleChange = (name: string, event: any) => {
    console.log('name', name);
    console.log('event', event);

    // @ts-ignore
    this.setState({ [name]: event });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log(this.state);

    const {
      id,
      editMode,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      nationality,
      residence,
      email,
      phoneNumber,
      jobTitle,
      skills,
      salary,
      contractType,
      cvFileName,
      visaRequired,
      status,
      experience
    } = this.state;

    const candidate: ICandidate = {
      firstName,
      lastName,
      prefix: '',
      gender,
      dateOfBirth,
      nationality,
      residence,
      contactInfo: {
        email,
        phoneNumber
      },
      jobTitle,
      skills,
      salary,
      contractType,
      cvFileName,
      visaRequired,
      status,
      experience
    };

    if (editMode) {
      // EDIT
      candidate.id = id;
      await this.props.updateCandidate(candidate);
      this.props.history.push(Routes.ADMIN);
    } else {
      // CREATE
      await this.props.createCandidate(candidate);
      this.props.history.push(Routes.ADMIN);
    }
  };

  render() {
    const {
      editMode,
      countryList,
      skillList,
      salaryRangeList,
      jobTitleList,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      nationality,
      residence,
      email,
      phoneNumber,
      jobTitle,
      skills,
      salary,
      contractType,
      cvFileName,
      visaRequired,
      status,
      experienceList,
      experience
    } = this.state;

    const contractTypeOptions = [
      { label: 'Permanent', value: 'PERMANENT' },
      { label: 'Freelance', value: 'FREELANCE' }
    ];

    const candidateStatusOptions = [
      { label: 'Available', value: 'AVAILABLE' },
      { label: 'Inactive', value: 'INACTIVE' }
    ];

    return (
      <div>
        <div className="content">
          <div className="container">
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="First Name">
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(event: any) => this.handleChange('firstName', event.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Last Name">
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(event: any) => this.handleChange('lastName', event.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Gender">
                    <Select
                      value={gender}
                      onChange={(event: any) => this.handleChange('gender', event)}
                    >
                      <Select.Option value="Male">Male</Select.Option>
                      <Select.Option value="Female">Female</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Birth Date">
                    {/* <input type="date" value={dateOfBirth}
                      onChange={(event: any) => this.handleChange('dateOfBirth', event.target.value)} /> */}
                    <DatePicker
                      format="YYYY-MM-DD"
                      allowClear={false}
                      value={
                        moment(dateOfBirth).isValid ? moment(dateOfBirth) : moment('1990-01-01')
                      }
                      onChange={(date: any, dateString: string) =>
                        this.handleChange('dateOfBirth', dateString)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Nationality">
                    <Select
                      showSearch
                      placeholder="Nationalities"
                      style={{ width: '100%' }}
                      value={nationality}
                      onChange={(event: any) => this.handleChange('nationality', event)}
                    >
                      {countryList.map((country: ICountry) => (
                        <Select.Option key={country.name} value={country.name}>
                          {country.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Residence">
                    <Select
                      showSearch
                      placeholder="Residence"
                      style={{ width: '100%' }}
                      value={residence}
                      onChange={(event: any) => this.handleChange('residence', event)}
                    >
                      {countryList.map((country: ICountry) => (
                        <Select.Option key={country.name} value={country.name}>
                          {country.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="E-mail">
                    <Input
                      type="email"
                      placeholder="E-mail"
                      value={email}
                      onChange={event => this.handleChange('email', event.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Skype ID / Phone Number">
                    <Input
                      type="tel"
                      placeholder="Skype ID / Phone Number"
                      value={phoneNumber}
                      onChange={event => this.handleChange('phoneNumber', event.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Type of Specialist">
                    <AutoComplete
                      placeholder="Type of Specialist"
                      dataSource={jobTitleList}
                      value={jobTitle}
                      onChange={event => this.handleChange('jobTitle', event)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Skills">
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="Skills"
                      value={skills}
                      onChange={(event: any) => this.handleChange('skills', event)}
                    >
                      {skillList}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Salary">
                    <Select
                      placeholder="Salary"
                      style={{ width: '100%' }}
                      value={salary}
                      onChange={(event: any) => this.handleChange('salary', event)}
                    >
                      {salaryRangeList.map((salary: ISalaryRange) => (
                        <Select.Option key={salary.name} value={salary.name}>
                          {salary.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Contract Type">
                    <Checkbox.Group
                      options={contractTypeOptions}
                      value={contractType}
                      onChange={(event: any) => this.handleChange('contractType', event)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Upload CV">
                    {/* <Upload onChange={this.handleUpload}>
                      <Button value={cvFileName}>
                        <Icon type="upload" /> Click to Upload
                      </Button>
                    </Upload> */}
                    {cvFileName && <Button onClick={() => downloadCV(cvFileName, `${firstName} ${lastName}`)}>Download current CV</Button>}
                    <input type="file" onChange={this.handleUpload} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Visa Status">
                    <Checkbox
                      checked={visaRequired}
                      onChange={event => this.handleChange('visaRequired', event.target.checked)}
                    >
                      Visa Required
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Candidate Status">
                    <Select
                      placeholder="Candidate Status"
                      style={{ width: '100%' }}
                      value={status}
                      onChange={(event: any) => this.handleChange('status', event)}
                    >
                      {candidateStatusOptions.map(status => (
                        <Select.Option key={status.value} value={status.value}>
                          {status.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Experience">
                    <Select
                      placeholder="Experience"
                      style={{ width: '100%' }}
                      value={experience}
                      onChange={(event: any) => this.handleChange('experience', event)}
                    >
                      {experienceList.map((experience: IExperience) => (
                        <Select.Option key={experience.name} value={experience.name}>
                          {experience.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <PrimaryButton type="primary" htmlType="submit">
                    {editMode ? 'Save changes' : 'Create'}
                  </PrimaryButton>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { createCandidate, updateCandidate }
)(CreateCandidate);
