import * as actionTypes from '../actions/actionTypes';
import { IBusinessUserDetails } from '../../constants/models';
import { normalizeCustomer } from '../normalizers/customer';

type State = {
  customers: IBusinessUserDetails[];
  customer: IBusinessUserDetails | null;
  isLoading: boolean;
  error: any;
};

const initialState: State = {
  customers: [],
  customer: null,
  isLoading: false,
  error: null
};

const customerReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMERS_REQUEST:
      return { ...state, customers: [], error: null, isLoading: true };
    case actionTypes.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.customers.map(normalizeCustomer),
        error: null,
        isLoading: false
      };
    case actionTypes.FETCH_CUSTOMERS_FAILURE:
      return { ...state, customers: [], error: action.error, isLoading: false };
    case actionTypes.FETCH_CUSTOMER_REQUEST:
      return { ...state, customer: null, error: null, isLoading: true };
    case actionTypes.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: normalizeCustomer(action.customer),
        error: null,
        isLoading: false
      };
    case actionTypes.FETCH_CUSTOMER_FAILURE:
      return { ...state, customer: null, error: action.error, isLoading: false };
    case actionTypes.UPDATE_CUSTOMER_REQUEST:
      return { ...state, error: null, isLoading: true };
    case actionTypes.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: [...state.customers, normalizeCustomer(action.customer)],
        error: null,
        isLoading: false
      };
    case actionTypes.UPDATE_CUSTOMER_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case actionTypes.DELETE_CUSTOMER_REQUEST:
      return { ...state, error: null, isLoading: true };
    case actionTypes.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.filter(c => c.id !== action.id),
        error: null,
        isLoading: false
      };
    case actionTypes.DELETE_CUSTOMER_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case actionTypes.ACTIVATE_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.ACTIVATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customers: state.customers.map(customer =>
          customer.id === action.id ? { ...customer, activated: true } : customer
        )
      };
    case actionTypes.DEACTIVATE_CUSTOMER_FAILURE:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.DEACTIVATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customers: state.customers.map(customer =>
          customer.id === action.id ? { ...customer, activated: false } : customer
        )
      };
    default:
      return { ...state };
  }
};

export default customerReducer;
