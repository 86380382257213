import { Dispatch } from 'redux';
import axios from '../../axios';
import * as actionTypes from './actionTypes';
import notify from '../../services/notificationCreator';
import { storage } from '../../utils/storage';
import { createBrowserHistory } from 'history';
import { Routes } from '../../constants/routes';

let interceptor: any;

const history = createBrowserHistory({ forceRefresh: true })

export interface IAuth {
  email: string
  token: string
  validUntil: string
  displayName: string
  permissions: string[]
}

export const login = (
  auth: IAuth
) => (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.LOGIN_REQUEST });

  const { email, displayName, token, validUntil, permissions } = auth

  storage.set('email', email);
  storage.set('displayName', displayName);
  storage.set('token', token);
  storage.set('tokenExpiration', validUntil);
  storage.set('permissions', JSON.stringify(permissions));

  dispatch({
    type: actionTypes.LOGIN_SUCCESS,
    auth
  });

  history.push(Routes.APP)
};

export const logout = () => (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: actionTypes.LOGOUT_REQUEST });
  return axios
    .post('logout')
    .then(() => {
      axios.interceptors.request.eject(interceptor);

      dispatch({
        type: actionTypes.LOGOUT_SUCCESS
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.LOGOUT_FAILURE
      });
    })
    .finally(() => {
      storage.clear();
    });
};

export const requestPasswordReset = (email: string) => (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: actionTypes.REQUEST_PASSWORD_RESET_REQUEST });
  return axios
    .post('reset-password', {
      email
    })
    .then(() => {
      dispatch({
        type: actionTypes.REQUEST_PASSWORD_RESET_SUCCESS
      });
    })
    .catch(error => {
      notify('error', error.response.data.message);
      dispatch({
        type: actionTypes.REQUEST_PASSWORD_RESET_FAILURE
      });
      throw error;
    });
};

export const resetPassword = (password: string, token: string) => (
  dispatch: Dispatch
): Promise<void> => {
  dispatch({ type: actionTypes.RESET_PASSWORD_REQUEST });
  return axios
    .put('reset-password', {
      token,
      password
    })
    .then(() => {
      dispatch({
        type: actionTypes.RESET_PASSWORD_SUCCESS
      });
    })
    .catch(error => {
      notify('error', error.response.data.message);
      dispatch({
        type: actionTypes.RESET_PASSWORD_FAILURE
      });
      throw error;
    });
};
