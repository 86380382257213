import React, { Component } from 'react';
import { Radio, Form, Input, Row, Col, Button, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { IBillingInfo, IBusinessUserDetails } from '../../constants/models';
import { AdminRoutes } from '../../constants/routes';
import { updateCustomer } from '../../store/actions/customerActions';
import axios from '../../axios';
import Plans from '../../constants/plans';
import PaymentFrequency from '../../constants/payment-frequency';

type State = {
  editMode: boolean;
  activated: boolean;
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  kvkNumber?: string;
  vatNumber?: string;
  billingInfo: IBillingInfo;
  subscription: IBusinessUserDetails['subscription'];
  subscribeNewsletter?: boolean;
  error: boolean;
};

type Props = RouteComponentProps<{ id: string }> & {
  updateCustomer: (customer: IBusinessUserDetails) => void;
};

class CreateCustomer extends Component<Props, State> {
  state: State = {
    editMode: false,
    activated: false,
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    kvkNumber: '',
    vatNumber: '',
    billingInfo: {
      email: '',
      address: {
        street: '',
        num: undefined,
        addition: '',
        postCode: '',
        city: ''
      }
    },
    subscription: {},
    subscribeNewsletter: false,
    error: false
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.path === AdminRoutes.EDIT_CUSTOMER_PATH) {
      if (match.params.id) {
        this.initializeEditForm(match.params.id);
      } else {
        console.log('invalid user id');
        this.setState({ error: true });
      }
    }
  }

  initializeEditForm = (id: string) => {
    axios
      .get(`customer/${id}`)
      .then(res => res.data)
      .then((customer: IBusinessUserDetails) => {
        console.log(customer);
        this.setState({
          editMode: true,
          ...customer,
          activated: customer.activated || false,
          email: customer.email,
          firstName: customer.firstName,
          lastName: customer.lastName,
          company: customer.company,
          kvkNumber: customer.kvkNumber,
          vatNumber: customer.vatNumber,
          subscription: customer.subscription || {},
          billingInfo: customer.billingInfo
            ? {
                address: customer.billingInfo.address
                  ? {
                      street: customer.billingInfo.address.street,
                      city: customer.billingInfo.address.city,
                      // @ts-ignore
                      num: customer.billingInfo.address.number,
                      addition: customer.billingInfo.address.addition,
                      postCode: customer.billingInfo.address.postCode
                    }
                  : {
                      street: '',
                      city: '',
                      postCode: ''
                    },
                email: customer.billingInfo.email
              }
            : {},
          subscribeNewsletter: customer.subscribeNewsletter,
          error: false
        });
      })
      .catch((err: any) => {
        console.log(err);
        this.setState({ error: true });
      });
  };

  handleChange = (e: any) => {
    const { name } = e.target;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    // @ts-ignore
    this.setState({ [name]: value });
  };

  handleSubscriptionChange = (e: any) => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      ...prevState,
      subscription: {
        ...prevState.subscription,
        [name]: value
      }
    }));
  };

  handleBillingChange = (e: any) => {
    const { name } = e.target;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    let billing: Partial<IBillingInfo> = { ...this.state.billingInfo };
    if (name === 'email') {
      billing = {
        ...billing,
        email: value
      };
    } else {
      billing = {
        ...billing,
        address: {
          ...billing.address,
          [name]: value
        }
      };
    }

    this.setState({ billingInfo: billing });
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    const {
      activated,
      billingInfo,
      company,
      email,
      editMode,
      kvkNumber,
      firstName,
      subscription,
      lastName,
      subscribeNewsletter,
      vatNumber
    } = this.state;
    const customer: IBusinessUserDetails = {
      activated,
      billingInfo: {
        email: billingInfo.email,
        address: billingInfo.address
          ? {
              addition: billingInfo.address.addition,
              city: billingInfo.address.city,
              // @ts-ignore
              number: billingInfo.address.num,
              postCode: billingInfo.address.postCode,
              street: billingInfo.address.street
            }
          : undefined
      },
      subscription,
      company,
      email,
      firstName,
      kvkNumber,
      lastName,
      subscribeNewsletter,
      vatNumber,
      id: this.props.match.params.id
    };
    if (editMode) {
      this.props.updateCustomer(customer);
    } else {
      // Create Customer:  will be implemented later
    }
    console.log(this.state);
  };

  render() {
    const {
      editMode,
      subscription,
      email,
      firstName,
      lastName,
      company,
      kvkNumber,
      vatNumber,
      billingInfo,
      subscribeNewsletter,
      error
    } = this.state;

    return (
      <div>
        {error && <Redirect to="/app" />}
        <div className="content">
          <div className="container">
            {editMode ? 'Edit' : 'Create'} Customer
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Company Name">
                    <Input
                      type="text"
                      name="company"
                      placeholder="Company Name"
                      value={company}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="KVK Number">
                    <Input
                      type="text"
                      name="kvkNumber"
                      placeholder="KVK Number"
                      value={kvkNumber}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="VAT Number">
                    <Input
                      type="text"
                      name="vatNumber"
                      placeholder="VAT Number"
                      value={vatNumber}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="First Name">
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Last Name">
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="E-mail Address">
                    <Input
                      type="email"
                      name="email"
                      placeholder="E-mail address"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Plan">
                    <Radio.Group
                      onChange={this.handleSubscriptionChange}
                      name="pricingPlan"
                      value={subscription ? subscription.pricingPlan : undefined}
                    >
                      {Object.entries(Plans).map(([key, value]) => (
                        <Radio.Button value={key} key={key}>
                          {value}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Payment frequency">
                    <Radio.Group
                      onChange={this.handleSubscriptionChange}
                      name="paymentFrequency"
                      value={subscription ? subscription.paymentFrequency : undefined}
                    >
                      {Object.entries(PaymentFrequency).map(([key, value]) => (
                        <Radio.Button value={key} key={key}>
                          {value}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item label="Billing Address">
                    <Input
                      type="text"
                      name="street"
                      placeholder="Street"
                      value={billingInfo.address ? billingInfo.address.street : ''}
                      onChange={this.handleBillingChange}
                    />
                    <Input
                      type="text"
                      name="num"
                      placeholder="Number"
                      value={billingInfo.address ? billingInfo.address.num : ''}
                      onChange={this.handleBillingChange}
                    />
                    <Input
                      type="text"
                      name="addition"
                      placeholder="Addition"
                      value={billingInfo.address ? billingInfo.address.addition : ''}
                      onChange={this.handleBillingChange}
                    />
                    <Input
                      type="text"
                      name="postCode"
                      placeholder="Post Code"
                      value={billingInfo.address ? billingInfo.address.postCode : ''}
                      onChange={this.handleBillingChange}
                    />
                    <Input
                      type="text"
                      name="city"
                      placeholder="City"
                      value={billingInfo.address ? billingInfo.address.city : ''}
                      onChange={this.handleBillingChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="E-mail Address for billing">
                    <Input
                      type="email"
                      name="email"
                      placeholder="E-mail Address for billing"
                      value={billingInfo.email}
                      onChange={this.handleBillingChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Subscribe for newsletter">
                    <Checkbox
                      onChange={this.handleChange}
                      value={subscribeNewsletter}
                      name="subscribeNewsletter"
                    >
                      Subscribe for newsletter
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit" disabled={error}>
                    {editMode ? 'Edit' : 'Create'}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { updateCustomer })(CreateCustomer);
