import { PublicClientApplication, EventType } from "@azure/msal-browser";

console.log(
  process.env.REACT_APP_MSAL_CLIENT_ID,
  process.env.REACT_APP_MSAL_TENANT_ID,
  process.env.REACT_APP_API_ENDPOINT
);

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
    postLogoutRedirectUri: "https://yourexpats.nl" // Indicates the page to navigate after logout.
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = await PublicClientApplication.createPublicClientApplication(
  msalConfig
);

msalInstance.addEventCallback(event => {
  // set active account after redirect
  const payload = event.payload;
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    payload &&
    payload.account
  ) {
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const loginRequest = {
  scopes: [`api://${msalConfig.auth.clientId}/access_via_approle_assignments`]
};
