import React, { useState } from 'react';
import { Table, Divider, Button, Icon, Input, Select } from 'antd';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { IBusinessUserDetails } from '../../../constants/models';
import { AdminRoutes } from '../../../constants/routes';
import PrimaryButton from '../../UI/PrimaryButton/PrimaryButton';

type Props = {
  customers: IBusinessUserDetails[];
  confirmDelete: (type: string, id: string) => void;
  activateCustomer: (method: boolean, id: string) => void;
};

const CustomerList: React.FC<Props> = ({ customers, confirmDelete, activateCustomer }: Props) => {
  const [searchText, setSearchText] = useState('');
  const [activeFilter, setActiveFilter] = useState('both');
  let searchInput: any;

  const getColumnSearchProps = (dataIndex: number | string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: {
      setSelectedKeys: any;
      selectedKeys: any;
      confirm: any;
      clearFilters: any;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: any) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  });

  const handleSearch = (selectedKeys: any, confirm: () => void) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => <span>{text}</span>,
      onFilter: (value: string, record: any) => record.email.indexOf(value) === 0,
      sorter: (a: any, b: any) => a.email.length - b.email.length,
      ...getColumnSearchProps('email')
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: string) => <span>{text}</span>,
      sorter: (a: any, b: any) => a.firstName.length - b.firstName.length,
      ...getColumnSearchProps('firstName')
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (text: string) => <span>{text}</span>,
      sorter: (a: any, b: any) => a.lastName.length - b.lastName.length,
      ...getColumnSearchProps('lastName')
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text: string[]) => <span>{text}</span>,
      sorter: (a: any, b: any) => a.company.length - b.company.length,
      ...getColumnSearchProps('company')
    },
    {
      title: 'Plan',
      dataIndex: 'pricingPlan',
      key: 'pricingPlan',
      sorter: (a: any, b: any) => a.company.length - b.company.length,
      ...getColumnSearchProps('pricingPlan')
    },
    {
      title: 'KVK Number',
      dataIndex: 'kvkNumber',
      key: 'kvkNumber',
      render: (text: string[]) => <span>{text}</span>,
      sorter: (a: any, b: any) => a.kvkNumber.length - b.kvkNumber.length,
      ...getColumnSearchProps('kvkNumber')
    },
    {
      title: 'VAT Number',
      dataIndex: 'vatNumber',
      key: 'vatNumber',
      render: (text: string[]) => <span>{text}</span>,
      sorter: (a: any, b: any) => a.vatNumber.length - b.vatNumber.length,
      ...getColumnSearchProps('vatNumber')
    },
    {
      title: 'Action',
      key: 'action',
      render: (customer: IBusinessUserDetails) => {
        return (
          <span>
            <Button onClick={() => activateCustomer(!customer.activated, customer.id || '')}>
              {customer.activated ? 'Deactivate' : 'Activate'}
            </Button>
            <Divider type="vertical" />
            <Link to={`${AdminRoutes.EDIT_CUSTOMER}/${customer.id}`}>
              <Button
                type="default"
                style={{
                  color: '#faad14'
                }}
              >
                Edit
              </Button>
            </Link>
            <Divider type="vertical" />
            <Button type="danger" onClick={() => confirmDelete('customer', customer.id || '')}>
              Delete
            </Button>
          </span>
        );
      }
    }
  ];

  return (
    <div>
      <FilterArea>
        Filter:
        <Select
          style={{ width: '200px', marginLeft: '10px' }}
          value={activeFilter}
          onChange={(value: string) => setActiveFilter(value)}
        >
          <Select.Option value="both">All</Select.Option>
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="inactive">Inactive</Select.Option>
        </Select>
      </FilterArea>
      <Table
        columns={columns}
        dataSource={customers.filter((customer: IBusinessUserDetails) => {
          switch (activeFilter) {
            case 'both':
              return true;
            case 'active':
              return customer.activated === true;
            case 'inactive':
              return customer.activated === false;
            default:
              return true;
          }
        })}
        pagination={false}
        bordered
        rowKey="id"
      />
      <div style={{ marginTop: '20px' }}>
        <Link to={AdminRoutes.CREATE_CUSTOMER}>
          <PrimaryButton type="primary">Create Customer</PrimaryButton>
        </Link>
      </div>
    </div>
  );
};

const FilterArea = styled.div`
  margin-bottom: 10px;
`;

export default CustomerList;
