import axios from '../../axios';
import * as actionTypes from './actionTypes';
import { ICandidate, IFilter } from '../../constants/models';
import notify from '../../services/notificationCreator';

export const fetchCandidates = (filters: IFilter = {}) => (dispatch: any) => {
  dispatch({ type: actionTypes.FETCH_CANDIDATES_REQUEST });
  return axios
    .get('candidate', {
      headers: { 'Content-Type': 'application/json' },
      params: filters,
      data: {}
    })
    .then(res => res.data)
    .then((candidates: ICandidate[]) => {
      dispatch({
        type: actionTypes.FETCH_CANDIDATES_SUCCESS,
        candidates
      });
    })
    .catch(err => {
      dispatch({
        type: actionTypes.FETCH_CANDIDATES_FAILURE,
        error: err
      });
      notify('error', 'Something went wrong while fetching candidates');
    });
};

export const fetchCandidate = (id: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.FETCH_CANDIDATE_REQUEST });
  return axios
    .get(`candidate/${id}`)
    .then(res => res.data)
    .then((candidate: ICandidate) => {
      dispatch({
        type: actionTypes.FETCH_CANDIDATE_SUCCESS,
        candidate
      });
    })
    .catch(err => {
      dispatch({
        type: actionTypes.FETCH_CANDIDATE_FAILURE,
        error: err
      });
      notify('error', 'Something went wrong while fetching candidate');
    });
};

export const createCandidate = (candidate: ICandidate) => (dispatch: any) => {
  dispatch({ type: actionTypes.CREATE_CANDIDATE_REQUEST });
  return axios
    .post('candidate', candidate)
    .then(res => res.data)
    .then((candidate: ICandidate) => {
      dispatch({
        type: actionTypes.CREATE_CANDIDATE_SUCCESS,
        candidate
      });
      notify('success', `${candidate.firstName} ${candidate.lastName} is successfully created!`);
    })
    .catch(err => {
      dispatch({
        type: actionTypes.CREATE_CANDIDATE_FAILURE,
        error: err
      });
      notify('error', 'Something went wrong while creating candidate');
    });
};

export const updateCandidate = (candidate: ICandidate) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_CANDIDATE_REQUEST });
  return axios
    .put(`candidate/${candidate.id}`, candidate)
    .then(res => res.data)
    .then((candidate: ICandidate) => {
      dispatch({
        type: actionTypes.UPDATE_CANDIDATE_SUCCESS,
        candidate
      });
      notify('success', `${candidate.firstName} ${candidate.lastName} is successfully updated!`);
    })
    .catch(error => {
      dispatch({
        type: actionTypes.UPDATE_CANDIDATE_FAILURE,
        error
      });
      notify('error', 'Something went wrong while updating candidate');
    });
};

export const deleteCandidate = (id: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_CANDIDATE_REQUEST });
  return axios
    .delete(`candidate/${id}`)
    .then(() => {
      dispatch({
        type: actionTypes.DELETE_CANDIDATE_SUCCESS,
        id
      });
      notify('success', `Candidate is successfully deleted!`);
    })
    .catch(error => {
      dispatch({
        type: actionTypes.DELETE_CANDIDATE_FAILURE,
        error
      });
      notify('error', 'Something went wrong while deleting candidate');
    });
};
