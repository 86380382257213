export const Routes = {
  HOME: '/',
  APP: '/app',
  RESUME: '/resume',
  RESUME_PATH: '/resume/:id',
  LOGIN: '/login',
  REGISTER: '/register',
  ADMIN: '/admin',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password'
};

export const AdminRoutes = {
  CREATE_CANDIDATE: `${Routes.ADMIN}/candidate/create`,
  EDIT_CANDIDATE: `${Routes.ADMIN}/candidate/edit`,
  EDIT_CANDIDATE_PATH: `${Routes.ADMIN}/candidate/edit/:id`,
  DELETE_CANDIDATE: `${Routes.ADMIN}/candidate/delete`,
  DELETE_CANDIDATE_PATH: `${Routes.ADMIN}/candidate/delete/:id`,

  CREATE_CUSTOMER: `${Routes.ADMIN}/customer/create`,
  EDIT_CUSTOMER: `${Routes.ADMIN}/customer/edit`,
  EDIT_CUSTOMER_PATH: `${Routes.ADMIN}/customer/edit/:id`,
  DELETE_CUSTOMER: `${Routes.ADMIN}/customer/delete`,
  DELETE_CUSTOMER_PATH: `${Routes.ADMIN}/customer/delete/:id`,
  ACTIVATE_CUSTOMER: `${Routes.ADMIN}/customer/activate`,
  ACTIVATE_CUSTOMER_PATH: `${Routes.ADMIN}/customer/activate/:id`
};
