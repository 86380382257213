import React, { Component } from "react";
import { Card } from "antd";
import { connect } from "react-redux";
import memoize from "memoize-one";
import axios from "../../axios";
import * as actionTypes from "../../store/actions/actionTypes";

import { fetchCandidates } from "../../store/actions/candidateActions";
import Filters from "../../components/Candidates/Filters/Filters";
import Candidate from "../../components/Candidates/CandidateListItem/CandidateListItem";
import {
  ICandidate,
  ICountry,
  ISkill,
  ISalaryRange,
  IJobTitle,
  IFilter,
  IExperience
} from "../../constants/models";

type Props = {
  fetchCandidates: (filters?: IFilter) => void;
  updateFilters: (newFilters: IFilter) => void;
  token: string;
  candidates: ICandidate[];
  permissions: string[];
  filters: IFilter;
};

type State = {
  countries: ICountry[];
  skills: ISkill[];
  salaryRanges: ISalaryRange[];
  jobTitles: IJobTitle[];
  experiences: IExperience[];
};

const getFormData = memoize(
  (): Promise<State> => {
    return Promise.all([
      axios.get("form/country").then(res => res.data),
      axios.get("form/skill").then(res => res.data),
      axios.get("form/salary-range").then(res => res.data),
      axios.get("form/job-title").then(res => res.data),
      axios.get("form/experience").then(res => res.data)
    ]).then(([countries, skills, salaryRanges, jobTitles, experiences]) => ({
      countries,
      skills,
      salaryRanges,
      jobTitles,
      experiences
    }));
  }
);
class Candidates extends Component<Props, State> {
  state = {
    countries: [],
    skills: [],
    salaryRanges: [],
    jobTitles: [],
    experiences: []
  };

  componentDidMount() {
    this.props.fetchCandidates(this.props.filters);
    getFormData().then((data: State) => this.setState(data));
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.isFiltersChanged(prevProps.filters)) {
      this.props.fetchCandidates(this.props.filters);
    }
  }

  isFiltersChanged = (prevFilters: IFilter) => {
    return !Object.keys(this.props.filters).every(filterItem => {
      // @ts-ignore
      return this.props.filters[filterItem] === prevFilters[filterItem];
    });
  };

  handleFilterChange = (name: string, event: any) => {
    this.props.updateFilters({
      [name]: event
    });
  };

  render() {
    const {
      skills,
      countries,
      salaryRanges,
      jobTitles,
      experiences
    } = this.state;
    const { filters } = this.props;

    return (
      <div>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <Card size="small" title="Filters">
                  <Filters
                    handleChange={this.handleFilterChange}
                    filters={filters}
                    skills={skills}
                    countries={countries}
                    salaryRanges={salaryRanges}
                    jobTitles={jobTitles}
                    experiences={experiences}
                  />
                </Card>
              </div>
              <div className="col-md-8">
                {this.props.candidates.map((candidate: ICandidate) => (
                  <Candidate
                    key={candidate.id}
                    candidate={candidate}
                    isAmbassador={
                      !this.props.permissions.includes(
                        "VIEW_UNRESTRICTED_CANDIDATE_DATA"
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  token: state.auth.token,
  candidates: state.candidate.candidates,
  permissions: state.auth.permissions,
  filters: state.filters
});

export default connect(mapStateToProps, dispatch => ({
  fetchCandidates: (filters?: IFilter) => fetchCandidates(filters)(dispatch),
  updateFilters: (newFilters: IFilter) => {
    dispatch({
      type: actionTypes.UPDATE_FILTERS,
      payload: newFilters
    });
  }
}))(Candidates);
