import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchCandidate } from '../../store/actions/candidateActions';
import Resume from '../../components/Candidates/Resume/Resume';
import { ICandidate } from '../../constants/models';
import { isAmbassadorSelector } from '../../store/selectors/auth';

type State = {};
type Props = {
  candidate: ICandidate;
  displayName: string;
  fetchCandidate: (id: string) => void;
  match: {
    params: {
      id: string;
    };
  };
  isAmbassador: boolean;
};

class CandidateDetails extends Component<Props, State> {
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.fetchCandidate(this.props.match.params.id);
    } else {
      console.log('invalid user id');
    }
  }

  render() {
    const { candidate, displayName, isAmbassador } = this.props;

    return (
      <div>
        <div className="content">
          <div className="container">
            {candidate ? (
              <Resume isAmbassador={isAmbassador} candidate={candidate} displayName={displayName} />
            ) : (
              <div>Invalid Candidate</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  candidate: state.candidate.candidate,
  displayName: state.auth.displayName,
  isAmbassador: isAmbassadorSelector(state)
});

export default connect(mapStateToProps, { fetchCandidate })(CandidateDetails);
