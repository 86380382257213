import axios from '../../axios';
import * as actionTypes from '../actions/actionTypes';
import { IBusinessUserDetails } from '../../constants/models';
import notify from '../../services/notificationCreator';

export const fetchCustomers = () => (dispatch: any) => {
  dispatch({ type: actionTypes.FETCH_CUSTOMERS_REQUEST });
  axios
    .get('customer')
    .then(res => res.data)
    .then((customers: IBusinessUserDetails[]) => {
      dispatch({ type: actionTypes.FETCH_CUSTOMERS_SUCCESS, customers });
    })
    .catch(error => {
      dispatch({ type: actionTypes.FETCH_CUSTOMERS_FAILURE, error });
      notify('error', 'Something went wrong while fetching customers.');
    })
}

export const fetchCustomer = (id: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.FETCH_CUSTOMER_REQUEST });
  axios
    .get(`customer/${id}`)
    .then(res => res.data)
    .then((customer: IBusinessUserDetails) => {
      console.log(customer);
      dispatch({ type: actionTypes.FETCH_CUSTOMER_SUCCESS, customer });
    })
    .catch(error => {
      dispatch({ type: actionTypes.FETCH_CUSTOMER_FAILURE, error });
      notify('error', 'Something went wrong while fetching customers.');
    })
}

export const updateCustomer = (customer: IBusinessUserDetails) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_CUSTOMER_REQUEST });
  return axios
    .put(`customer/${customer.id}`, customer)
    .then(res => res.data)
    .then((customer: IBusinessUserDetails) => {
      dispatch({ type: actionTypes.UPDATE_CUSTOMER_SUCCESS, customer })
      notify('success', `${customer.firstName} ${customer.lastName} is successfully updated!`);
    })
    .catch(error => {
      dispatch({ type: actionTypes.UPDATE_CUSTOMER_FAILURE, error });
      notify('error', 'Something went wrong while updating customer.');
    })
}

export const deleteCustomer = (id: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_CUSTOMER_REQUEST });
  return axios
    .delete(`customer/${id}`)
    .then(() => {
      dispatch({
        type: actionTypes.DELETE_CUSTOMER_SUCCESS,
        id
      });
      notify('success', `Customer is successfully deleted!`);
    })
    .catch(error => {
      dispatch({
        type: actionTypes.DELETE_CUSTOMER_FAILURE,
        error
      });
      notify('error', 'Something went wrong while deleting customer');
    })
}

export const activateCustomer = (id: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.ACTIVATE_CUSTOMER_REQUEST });
  return axios
    .post(`customer/${id}/activate`, { id })
    .then(() => {
      dispatch({
        type: actionTypes.ACTIVATE_CUSTOMER_SUCCESS,
        id
      });
      notify('success', `Customer is successfully activated!`);
    })
    .catch(error => {
      dispatch({
        type: actionTypes.ACTIVATE_CUSTOMER_FAILURE,
        error
      });
      notify('error', 'Something went wrong while activating customer');
    })
}

export const deactivateCustomer = (id: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.DEACTIVATE_CUSTOMER_REQUEST });
  return axios
    .post(`customer/${id}/deactivate`, { id })
    .then(() => {
      dispatch({
        type: actionTypes.DEACTIVATE_CUSTOMER_SUCCESS,
        id
      });
      notify('success', `Customer is successfully deactivated!`);
    })
    .catch(error => {
      dispatch({
        type: actionTypes.DEACTIVATE_CUSTOMER_FAILURE,
        error
      });
      notify('error', 'Something went wrong while deactivating customer');
    })
}