import React, { useEffect } from 'react';
import { Tabs, Modal } from 'antd';
import { connect } from 'react-redux';

import { fetchCandidates, deleteCandidate } from '../../store/actions/candidateActions';
import {
  fetchCustomers,
  deleteCustomer,
  activateCustomer,
  deactivateCustomer
} from '../../store/actions/customerActions';
import CandidateList from '../../components/Admin/CandidateList/CandidateList';
import CustomerList from '../../components/Admin/CustomerList/CustomerList';
import { ICandidate, IBusinessUserDetails } from '../../constants/models';

type Props = {
  candidates: ICandidate[];
  customers: IBusinessUserDetails[];
  fetchCandidates: () => void;
  fetchCustomers: () => void;
  deleteCandidate: (id: string) => void;
  deleteCustomer: (id: string) => void;
  activateCustomer: (id: string) => void;
  deactivateCustomer: (id: string) => void;
}

const Admin: React.FC<Props> = ({
  candidates,
  customers,
  fetchCandidates,
  fetchCustomers,
  deleteCandidate,
  deleteCustomer,
  activateCustomer,
  deactivateCustomer
}: Props) => {
  useEffect(() => {
    fetchCandidates();
    fetchCustomers();
  }, [])

  const showDeleteConfirmation = (type: string, id: string) => {
    Modal.confirm({
      title: 'Do you want to delete?',
      content: 'When clicked the OK button, this dialog will be closed and operation will be completed.',
      onOk: () => {
        switch (type) {
          case 'candidate':
            deleteCandidate(id);
            break;
          case 'customer':
            deleteCustomer(id);
            break;
        }
      },
      onCancel() { },
    });
  }

  const changeCustomerStatus = (type: boolean, id: string) => {
    if (type) {
      activateCustomer(id);
    } else {
      deactivateCustomer(id);
    }
  }

  return (
    <div>
      <div className="content">
        <div className="container">
          <Tabs defaultActiveKey="1" tabBarStyle={{ color: '#000' }}	>
            <Tabs.TabPane tab="Candidates" key="1">
              <CandidateList candidates={candidates} confirmDelete={showDeleteConfirmation} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Customers" key="2">
              <CustomerList
                customers={customers}
                confirmDelete={showDeleteConfirmation}
                activateCustomer={changeCustomerStatus} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  permissions: state.auth.permissions,
  candidates: state.candidate.candidates,
  customers: state.customer.customers
})

export default connect(mapStateToProps, {
  fetchCandidates,
  fetchCustomers,
  deleteCandidate,
  deleteCustomer,
  activateCustomer,
  deactivateCustomer
})(Admin);
