import React, { FC, memo } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import Header from "../containers/Header/Header";

type Props = RouteProps & {
  token?: string;
  component: any;
  permissions?: readonly string[];
};

export const PrivateRoute: FC<Props> = memo(function PrivateRoute({
  component: Component,
  token,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <React.Fragment>
            <Header />
            <Component {...props} />
          </React.Fragment>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
});
