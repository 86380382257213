export const PINK_1 = '#620042';
export const PINK_2 = '#870557';
export const PINK_3 = '#A30664';
export const PINK_4 = '#BC0A6F';
export const PINK_5 = '#DA127D';
export const PINK_6 = '#E8368F';
export const PINK_7 = '#F364A2';
export const PINK_8 = '#FF8CBA';
export const PINK_9 = '#FFB8D2';
export const PINK_10 = '#FFE3EC';

export const GRAY_1 = '#1F2933';
export const GRAY_2 = '#323F4B';
export const GRAY_3 = '#3E4C59';
export const GRAY_4 = '#52606D';
export const GRAY_5 = '#616E7C';
export const GRAY_6 = '#7B8794';
export const GRAY_7 = '#9AA5B1';
export const GRAY_8 = '#CBD2D9';
export const GRAY_9 = '#E4E7EB';
export const GRAY_10 = '#F5F7FA';

export const PRIMARY = '#fa7452';