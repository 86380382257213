import axios from "../axios";
import notify from '../services/notificationCreator';

export const downloadCV = (fileName: string, resumeName = 'file') =>
  axios(`cv-file/${fileName}`, { responseType: 'blob', method: 'GET' })
    .then(res => res.data)
    .then((cv: BlobPart) => {
      const newBlob = new Blob([cv], { type: 'application/pdf' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = `${resumeName}.pdf`;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
      }, 100);
    })
    .catch(error => {
      console.log(error);
      notify('error', 'CV is not available, please contact us.');
    });
