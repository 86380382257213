// GET CANDIDATES
export const FETCH_CANDIDATES_REQUEST = 'FETCH_CANDIDATES_REQUEST';
export const FETCH_CANDIDATES_SUCCESS = 'FETCH_CANDIDATES_SUCCESS';
export const FETCH_CANDIDATES_FAILURE = 'FETCH_CANDIDATES_FAILURE';

// GET CANDIDATE
export const FETCH_CANDIDATE_REQUEST = 'FETCH_CANDIDATE_REQUEST';
export const FETCH_CANDIDATE_SUCCESS = 'FETCH_CANDIDATE_SUCCESS';
export const FETCH_CANDIDATE_FAILURE = 'FETCH_CANDIDATE_FAILURE';

// CREATE CANDIDATE
export const CREATE_CANDIDATE_REQUEST = 'CREATE_CANDIDATE_REQUEST';
export const CREATE_CANDIDATE_SUCCESS = 'CREATE_CANDIDATE_SUCCESS';
export const CREATE_CANDIDATE_FAILURE = 'CREATE_CANDIDATE_FAILURE';

// DELETE CANDIDATE
export const DELETE_CANDIDATE_REQUEST = 'DELETE_CANDIDATE_REQUEST';
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS';
export const DELETE_CANDIDATE_FAILURE = 'DELETE_CANDIDATE_FAILURE';

// UPDATE CANDIDATE
export const UPDATE_CANDIDATE_REQUEST = 'UPDATE_CANDIDATE_REQUEST';
export const UPDATE_CANDIDATE_SUCCESS = 'UPDATE_CANDIDATE_SUCCESS';
export const UPDATE_CANDIDATE_FAILURE = 'UPDATE_CANDIDATE_FAILURE';

// LOGIN
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// LOGOUT
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// REQUEST PASSWORD RESET
export const REQUEST_PASSWORD_RESET_REQUEST = 'REQUEST_PASSWORD_RESET_REQUEST';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';

// RESET PASSWORD
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

// REGISTER
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// GET CUSTOMERS
export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

// GET CUSTOMER
export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

// DELETE CUSTOMER
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

// UPDATE CUSTOMER
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

// ACTIVATE CUSTOMER
export const ACTIVATE_CUSTOMER_REQUEST = 'ACTIVATE_CUSTOMER_REQUEST';
export const ACTIVATE_CUSTOMER_SUCCESS = 'ACTIVATE_CUSTOMER_SUCCESS';
export const ACTIVATE_CUSTOMER_FAILURE = 'ACTIVATE_CUSTOMER_FAILURE';

// DEACTIVATE CUSTOMER
export const DEACTIVATE_CUSTOMER_REQUEST = 'DEACTIVATE_CUSTOMER_REQUEST';
export const DEACTIVATE_CUSTOMER_SUCCESS = 'DEACTIVATE_CUSTOMER_SUCCESS';
export const DEACTIVATE_CUSTOMER_FAILURE = 'DEACTIVATE_CUSTOMER_FAILURE';

// UPDATE FILTERS
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
