import React from 'react';
import { Select, Divider, Checkbox } from 'antd';

import './Filters.css';
import {
  ICountry,
  ISkill,
  ISalaryRange,
  IJobTitle,
  IFilter,
  IExperience
} from '../../../constants/models';

type Props = {
  countries: ICountry[];
  skills: ISkill[];
  salaryRanges: ISalaryRange[];
  jobTitles: IJobTitle[];
  experiences: IExperience[];
  handleChange: (name: string, event: any) => void;
  filters: Partial<IFilter>;
};

const Filters: React.FC<Props> = ({
  countries,
  skills,
  salaryRanges,
  jobTitles,
  experiences,
  handleChange,
  filters
}: Props) => {
  const contractTypeOptions = [
    { label: 'Permanent', value: 'PERMANENT' },
    { label: 'Freelance', value: 'FREELANCE' }
  ];

  const jobTitleOptions = jobTitles.map(jobTitle => {
    return { label: jobTitle.name, value: jobTitle.name };
  });

  const experienceOptions = experiences.map(experience => {
    return { label: experience.name, value: experience.name };
  });

  const salaryOptions = salaryRanges.map(salary => {
    return { label: salary.name, value: salary.name };
  });

  const visaOptions = [
    { label: 'Both', value: '' },
    { label: 'Visa Required', value: 'true' },
    { label: 'Visa is Not Required', value: 'false' }
  ];

  return (
    <div>
      <div>
        <Divider>Type of Specialist</Divider>
        <div>
          <Checkbox.Group
            value={filters.jobTitle}
            options={jobTitleOptions}
            onChange={event => handleChange('jobTitle', event)}
          />
        </div>
      </div>
      <div>
        <Divider>Skills</Divider>
        <Select
          showSearch
          value={filters.skill}
          mode="multiple"
          placeholder="Skills"
          style={{ width: '100%' }}
          onChange={(event: string[]) => handleChange('skill', event)}
        >
          {skills.map(skill => (
            <Select.Option key={skill.name} value={skill.name}>
              {skill.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <Divider>Salary Indication</Divider>
        <Checkbox.Group
          value={filters.salaryRange}
          options={salaryOptions}
          onChange={event => handleChange('salaryRange', event)}
        />
      </div>
      <div>
        <Divider>Experiences</Divider>
        <Checkbox.Group
          value={filters.experience}
          options={experienceOptions}
          onChange={event => handleChange('experience', event)}
        />
      </div>
      <div>
        <Divider>Contract type</Divider>
        <div>
          <Checkbox.Group
            value={filters.contractType}
            options={contractTypeOptions}
            onChange={event => handleChange('contractType', event)}
          />
        </div>
      </div>
      <div>
        <Divider>Visa Status</Divider>
        {/* <Checkbox onChange={(event) => props.handleChange('visaRequired', event.target.checked)}>Visa Required</Checkbox> */}
        <Select
          value={filters.visaRequired}
          style={{ width: '100%' }}
          placeholder="Visa Status"
          onChange={(event: any) => handleChange('visaRequired', event)}
        >
          {visaOptions.map(option => (
            <Select.Option key={option.label} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <Divider>Residing Country</Divider>
        <Select
          value={filters.residence}
          showSearch
          mode="multiple"
          placeholder="Countries"
          style={{ width: '100%' }}
          onChange={(event: string[]) => handleChange('residence', event)}
        >
          {countries.map(country => (
            <Select.Option key={country.name} value={country.name}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <Divider>Nationality</Divider>
        <Select
          value={filters.nationality}
          showSearch
          mode="multiple"
          placeholder="Nationalities"
          style={{ width: '100%' }}
          onChange={(event: string[]) => handleChange('nationality', event)}
        >
          {countries.map(country => (
            <Select.Option key={country.name} value={country.name}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default Filters;
